<template>
  <div>
    <b-tabs
      v-if="!isLevier"
      v-model="statusTab"
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab.tab"
        :title="tab.name"
        :active="tab.tab === 'active'"
        @click="tabStChange(tab.status)"
      />
    </b-tabs>
    <div class="d-flex justify-content-between">
      <div v-can="'settings.blacklists-store'">
        <b-button
          :variant="isOpenBlacklist?'warning':'outline-warning'"
          class="btn-tour-skip text-right mb-2"
          :disabled="isOpenBlacklist && !blacklistForm.pins.length"
          @click="blacklistButtonClick"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            size="16"
          /> Qora ro'yhatga
          qo'shish
        </b-button>
        <a
          v-if="isOpenBlacklist"
          class="link ml-2"
          @click="closeBlackList"
        >
          <feather-icon
            icon="XIcon"
            size="16"
          /> Bekor qilish
        </a>
      </div>

      <b-button
        variant="success"
        class="btn-tour-skip text-right mb-2"
        @click="tableToExcel"
      >
        <feather-icon
          icon="DownloadIcon"
          size="16"
        /> Юклаб олиш
      </b-button>
    </div>
    <b-overlay :show="loading">
      <template v-if="contracts">
        <TableCheckbox
          v-if="isOpenBlacklist"
          :items="contracts.data"
          :columns="columns"
          :total="contracts.total"
          :page="page"
          :has-show="true"
          model="payment"
          :filter="filterModel"
          @getItems="getItems"
          @selectedRows="selectedRows"
          @onPageChange="(p) => (page = p)"
        />
        <good-table-column-search
          v-else
          :items="contracts.data"
          :columns="columns"
          :total="contracts.total"
          :page="page"
          :has-show="true"
          model="payment"
          :filter="filterModel"
          @getItems="getItems"
          @onPageChange="(p) => (page = p)"
        />
        <good-table-column-search
          v-show="false"
          ref="table"
          class="tablee"
          :items="contracts.data"
          :columns="columns"
          :total="contracts.total"
          :page="page"
          :has-show="true"
          model="payment"
          :filter="filterModel"
          @getItems="getItems"
          @onPageChange="(p) => (page = p)"
        />
      </template>
      <template v-else>
        <div style="height: 50vh" />
      </template>
    </b-overlay>
    <addToBlacklistModal
      :visible="openBlacklistConfirmModal"
      title="Qora ro'yhatga kiritish"
      @close="openBlacklistConfirmModal=false"
      @confirmed="confirmAddToBlacklist"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import TableCheckbox from '@/views/table/vue-good-table/GoodTableContractCashTable.vue'
import addToBlacklistModal from '@/components/modals/addToBlacklistModal.vue'
import { showToast } from '@/utils/toast'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    TableCheckbox,
    addToBlacklistModal,
  },
  data() {
    return {
      page: 1,
      loading: false,
      statusTab: 1,
      blacklistForm: {
        pins: [],
        comment: '',
      },
      isOpenBlacklist: false,
      openBlacklistConfirmModal: false,
      filterModel: {
        page: 1,
        per_page: 50,
        number: null,
        court_status: 1,
        tab: '',
        appends: 'payment_amount_this_month|payment_amount_until_this_month',
        client_id: null,
        relations: 'client',
      },
      tabs: [
        { status: 1, tab: 'active', name: 'Xat berilganlar' },
        { status: 2, tab: 'abouttoclose', name: 'Qaror kuchga kirganlar' },
        { status: 3, tab: 'closed', name: 'Yopilgan' },
        { status: 4, tab: 'all', name: 'Barchasi' },
      ],
      columns: [],
      contracts: null,
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    ...mapGetters({
      // contracts: 'contract/GET_ITEMS',
    }),
    isHasPermission() {
      return (
        this.user.role.name == 'cashier'
        || this.user.role.name == 'levier'
        || this.user.role.name == 'admin'
      )
    },
    title() {
      const activeTab = this.tabs.find(el => el.tab == this.filterModel.tab)
      if (activeTab) {
        return activeTab.name
      }
      return 'Список договора'
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (this.isLevier) {
      this.filterModel.status = 1
      this.statusTab = 3
      this.filterModel.is_overdue = true
    }
    if (this.$route.query && this.$route.query.client_id) {
      this.filterModel.client_id = this.$route.query.client_id
    }
    this.getItems()
    this.getColumns()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'contract/index',
      destroyAction: 'region/destroy',
      getCitiesAction: 'city/index',
      destroyCityAction: 'city/destroy',
      storeBlacklist: 'resource/storeBlacklist',
    }),
    getColumns() {
      this.columns = [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Номер договора'),
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Фамилия'),
          field: 'client.lastname',
          showField: this.hasPermission('contracts.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Имя'),
          field: 'client.firstname',
          showField: this.hasPermission('contracts.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Отчества'),
          field: 'client.middlename',
          showField: this.hasPermission('contracts.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Телефон'),
          field: 'client.phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Паспорт'),
          field: this.passportField,
          filterField: 'passport',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('ПИНФЛ'),
          field: 'client.pin',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Просроченная сумма'),
          field: 'overdue_amount',
          isDangerAmount: true,
        },
        {
          label: this.$t('Хат берилган сана'),
          field: 'court_letter_date',
          isDate: true,
        },
        {
          label: this.$t('Қарор кучга кирган сана'),
          field: 'court_decision_date',
          isDate: true,
        },
        {
          label: this.$t('Қарор чиқган сумма'),
          field: 'decition_summa',
          field1: 'penalty_amount',
          field2: 'overdue_amount_at_decision',
          isDangerAmount: true,
        },
        {
          label: this.$t('Шундан асосий қарздорлик сумма'),
          field: 'overdue_amount_at_decision', // overdue_amount_at_decision
          isDangerAmount: true,
        },
        {
          label: this.$t('Шундан жарима суммаси'),
          field: 'penalty_amount', // penalty_amount
          isDangerAmount: true,
        },
        {
          label: this.$t('Жами тўланган сумма'),
          field: 'decition_payed_summa', // payment_amount_this_month+payment_amount_until_this_month
          field1: 'payment_amount_this_month',
          field2: 'payment_amount_until_this_month',
          isDangerAmount: true,
        },

        {
          label: this.$t('Жорий ойда тўланган сумма'),
          field: 'payment_amount_this_month', // payment_amount_this_month
          isDangerAmount: true,
        },
        {
          label: this.$t('Жорий ойгача тўланган сумма'),
          field: 'payment_amount_until_this_month', // payment_amount_until_this_month
          isDangerAmount: true,
        },
        {
          label: this.$t('Изоҳ'),
          field: 'court_decision_comment',
          isDate: true,
        },

        {
          label: this.$t('Статус'),
          field: 'court_status',
        },
      ]
    },
    passportField(row) {
      if (row.client && row.client.doc_series) {
        return row.client.doc_series + row.client.doc_number
      }
      return null
    },
    selectedRows(rows) {
      this.blacklistForm.pins = []
      if (rows && rows.length) {
        rows.forEach(el => {
          this.blacklistForm.pins.push(el.pin)
        })
      }
    },
    closeBlackList() {
      this.isOpenBlacklist = false
      this.blacklistForm.pins = []
    },
    blacklistButtonClick() {
      if (!this.isOpenBlacklist) {
        this.isOpenBlacklist = true
      } else {
        this.openBlacklistConfirmModal = true
      }
    },
    confirmAddToBlacklist(formData) {
      this.storeBlacklist({ ...formData, pins: this.blacklistForm.pins })
        .then(res => {
          if (res.success) {
            showToast('success', this.$t('Успешно сохранено'))
            this.openBlacklistConfirmModal = false
            this.closeBlackList()
          }
        })
    },
    tabChange(status) {
      if (status == 3) {
        this.filterModel.status = 1
        this.filterModel.is_overdue = true
        this.filterModel.images_confirmation_status = 1
      } else if (status == 4) {
        this.filterModel.status = 1
        this.filterModel.is_rejected = 1
        this.filterModel.is_overdue = null
        this.filterModel.images_confirmation_status = null
      } else if (status == 5) {
        this.filterModel.images_confirmation_status = null
        this.filterModel.status = 1
        this.filterModel.is_rejected = null
        this.filterModel.is_overdue = null
      } else {
        this.filterModel.images_confirmation_status = 1
        this.filterModel.status = status
        this.filterModel.is_rejected = 0
        this.filterModel.is_overdue = null
      }
      this.getItems()
    },
    tabStChange(tab) {
      if (tab == 4) {
        this.filterModel.court_status = 'all'
      } else {
        this.filterModel.court_status = tab
      }
      setTimeout(() => {
        this.page = 1
        this.getItems()
      }, 0)
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
        .then(res => {
          this.contracts = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    async tableToExcel(table) {
      this.filterModel.per_page = 9999
      await this.getItems()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )
      const secondRow = tableeee.querySelector('thead tr:nth-child(2)')
      if (secondRow) {
        secondRow.remove()
      }
      const rows = tableeee.querySelectorAll('table tr')
      rows.forEach(row => {
        const lastCell = row.lastElementChild
        if (lastCell.querySelector('td.vgt-left-align svg')) {
          lastCell.remove()
        }
      })

      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
        const link = document.createElement('a')
        link.download = `${this.title}.xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
      }, 10)
    },
  },
}
</script>

<style scoped></style>
